import React,{ useEffect}  from 'react'
import './footer.css'
import img from '../../Assets/Logo2.png'
import { ImFacebook, ImInstagram } from 'react-icons/im'
import { AiFillInstagram, AiFillTwitterCircle } from 'react-icons/ai'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {

  useEffect(() => {
    Aos.init({duration:2000})
  },
  
  [])
  return (
 <div className="footer">
    <div className="secContainer container grid">
         <div data-aos="fade-up" data-aos-duration="2000"  className="logoDiv">



             <div className="footerLogo">
               
                <img src={img} alt="Image title"/>
             </div>

            <div className="socials flex">
            <a href='https://www.facebook.com/eaddorgtr'><ImFacebook className='icon'/></a>
           <a href='https://www.instagram.com/eadd.org.tr/'> <AiFillInstagram className='icon'/></a>
            <a href='https://www.facebook.com/eaddorgtr'><AiFillTwitterCircle className='icon'/></a>
          </div>
         </div>
         <div  data-aos="fade-up" data-aos-duration="4000"className="footerLinks"> 
          <span className="linkTitle">
             Banka Bilgilerimiz
          </span>
          <li>
            <a href='#'> Ziraat Bankası Rahmanlar Şubesi</a>
          </li>
          <li>
            <a href='#'>TR 78 0001 0020 9986 0340 5150 01 TL</a>
          </li>
          <li>
            <a href='#'>TR 51 0001 0020 9986 0340 5150 02 $</a>
          </li>
          <li>
            <a href='#'>TR 24 0001 0020 9986 0340 5150 03 €</a>
          </li>
          </div>
          
          

          <div  data-aos="fade-up" data-aos-duration="5000" className="footerLinks"> 
          <span className="linkTitle">
          Bize Ulaşın
          </span>
          <span className='Phone'>0 (216) 306 70 71</span>
          <span className='email'>bilgi@eadd.org.tr</span>
          <span className='adress'>Atalar Cad. Sümer Sok. Çelebi Apt 25A Kartal / İstanbul</span>
          </div>

    </div>
 </div>
  )
}

export default Footer