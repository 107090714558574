import React,{ useEffect} from 'react'
import './project.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

import img8 from '../../Assets/f1.png'
import img9 from '../../Assets/f2.png'
import img10 from '../../Assets/f3.png'
import img11 from '../../Assets/f4.png'

const Pro=[
{
  id:1,
  imgSrc:img8,
  Title:'Tekerlekli Sandalye',
  status:'Aktif',
  Details:'Derneğimiz üyelerimizin tekerlekli sandalye ihtiyaçları için bağış kampanyaları düzenler',
},

{
  id:2,
  imgSrc:img9,
  Title:'Tedavi',
  status:'Aktif',
  Details:'Derneğimiz, Maddi durumu yetersiz olup bu nedenle tedavi olamayan engellilerimizin tedavilerini yaptırır',
},
{
  id:3,
  imgSrc:img10,
  Title:'Burs',
  status:'Aktif',
  Details:'Derneğimiz, Engelli ve ihtiyaç sahibi öğrencilerimizin burs talepleri alınarak burs taleplerini karşılar'
},

{
  id:4,
  imgSrc:img11,
  Title:'Bağış ve Yardım',
  status:'Aktif',
  Details:'Dernek üyelikleri onaylanmış olan ihtiyaçlı engellilerimizin ihtiyaçları için bağış kampanyaları düzenler'
}, 
]
const Project = () => {
  useEffect(() => {
    Aos.init({duration:2000})
  },
[])
  
  return (
    <section className='project container section'>
       <div className='secContainer'>
         <div data-aos="fade-up" data-aos-duration="2000"  className='secIntro'>
            <h4 className='secTitle'>
              <p> Yardım Faaliyetlerimiz</p>
            </h4>
         </div>
         <div className='mainContent grid'>   
            {
              Pro.map(({id,imgSrc,Title,status,Details})=>
                {
                return(
                <div data-aos="fade-up" data-aos-duration="2000" className='singleProject'> 
                <div className='projectImage'>
                <img src={imgSrc} alt='başlık'/>
                </div>
                 <div className='projectBody'>
                  <div className="Title flex">
                    <h4> 
                      {Title}
                    </h4>
                 {/* <span className="projectdonationcount">
                  10 kişi
                  </span> */}
                    <span className='status'> 
                        {status}
                    </span>
                  </div>
                  <div className='Details'>
                    <p> {Details} </p>
                  </div>
                 </div>
            {/* <button className='btn flex'>
              Detaylı Bilgi
          </button>  */} </div>)})}
          </div>
          </div>    
          </section> )}

export default Project