import React,{ useEffect} from 'react'
import './popular.css'
import { BsArrowRightShort } from 'react-icons/bs'


import img from '../../Assets/11.png'
import img2 from '../../Assets/3.png'
import img3 from '../../Assets/4.png'
import img4 from '../../Assets/5.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Data=[
  {
    id:1,
    imgSrc:img,
    ProjectTitle:'Dönüşüm Atölyesi',
    ProjectDescription:' Dernek Üye Ve Gönüllülerimizin El Emeği Çalışmaları ',
    
  },
  {
    id:2,
    imgSrc:img2,
    ProjectTitle:'Tekerlekli Sandalye Bağış Kampanyaları',
    ProjectDescription:'Dernek Üyelerimiz İçin Tekerlekli Sandalye Bağış Kampanyaları ',
    
  },

  {
    id:3,
    imgSrc:img3,
    ProjectTitle:'Dernek Etkinlikleri',
    ProjectDescription:'Dernek Gönüllü ve Üyelerimiz İle Düzenlenen Etkinlikler  ',
    
  },

  {
    id:4,
    imgSrc:img4,
    ProjectTitle:'İleri Dönüşüm Çalışmaları ',
    ProjectDescription:'Derneğimizin Üye Ve Gönüllüleri İle Dönüşüme Katkı Sağlayan Çalışmaları',
    
  },
  


]


const Popular = () => {

  useEffect(() => {
    Aos.init({duration:2000})
  },
  
  [])
  return (

    <section className='popular section container'>
      <div className="secContainer">

        <div className="secHeader flex">

          <div data-aos="fade-right" data-aos-duration="2500"  className="textDiv">
            <h2 className="secTitle">
               Projelerimiz
            </h2>
            <noscript>
    <p> JavaScript devre dışıysa, kullanıcılar bir uyarı mesajı görür ve JavaScript'i etkinleştirme hakkında bilgi alırlar.</p>
    <a href="">Learn how to enable JavaScript</a>
           </noscript>
            <p>
              Aktif Projeler Ve Etkinlikler
            </p>
          </div>
          {/*  <div className="iconsDiv flex">
              <BsArrowLeftShort class="Icon leftIcon" />
              <BsArrowRightShort class="Icon" />

            </div>*/}

        </div>

        <div className="mainContent grid">
          {
            Data.map(({id,imgSrc,ProjectTitle,ProjectDescription})=>{
              return(
                <div data-aos="fade-up"   className="singleProject">
                  <div className="destImage">
                    <img src={imgSrc} alt="Image title"/>
                       <div className="overlayInfo">
                       <a href='https://www.instagram.com/eadd.org.tr/'> 
                        <h3> {ProjectTitle}</h3>
                        <p>{ProjectDescription}</p>
                      <BsArrowRightShort className='Icon'/>  </a>             
                      </div>
                  </div>
               {/* <div className="destFooter">
                <div className="number">
                  0{id}
                </div>
                <div className="destText flex">
                  <h6>{ProjectDate} </h6>
                  <span className='flex'>
                    <span className="dot">
                      <BsDot className="Icon"/>
                    </span>
                  </span>
                </div>
                </div>   */}
          </div>
              )
            })
          }
        </div>
      </div>
    </section>
   
  )
}

export default Popular