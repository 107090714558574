import React,{ useEffect}  from 'react'
import './about.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import img from '../../Assets/a1.png'
import img10 from '../../Assets/a2.png'
import img11 from '../../Assets/a3.png'
import video from '../../Assets/video.mp4'

const About = () => {
  useEffect(() => {
    Aos.init({duration:2000})
  },
  
  [])
  return (
   <section className='about section'>
    <div className='secContainer'>
      <div className='title'>
        <h2> Biz Kimiz?</h2>
      </div>
    <div className='mainContent container grid'>
      <div data-aos="fade-up" data-aos-duration="2000"  className='singleItem'>
         <img src={img} alt=''/>
         <h3> Dönüşüm Atölyelerimiz</h3>

         <p>Dönüşüm Atölyemizde Engelli ve Ailelerimizin el becerilerini geliştirmek ve istihdam yaratmak</p>
      </div>

      <div data-aos="fade-up" data-aos-duration="2500" className='singleItem'>
         <img src={img10} alt=''/>
         <h3> Özel Çocuklara ve Ailelerine Koçluk</h3>

         <p> Ailelerimize sosyal aktiviteler ve çeşitli konularda koçluk desteği vererek, sosyal yaşama daha iyi hazirlamak</p>
      </div>

      <div data-aos="fade-up" data-aos-duration="3000"  className='singleItem'>
         <img src={img11} alt=''/>
         <h3> Bağış Ve Yardım Faaliyetlerimiz</h3>

         <p>İhtiyaç sahibi Engellilerimizin tedavi, eğitim, burs, Ayni yardım ve T.Sandalye ihtiyaçlarını karşılamak</p>
      </div>

    </div>

<div className='videoCard container'>
<div className="cardContent grid">
<div data-aos="fade-right" data-aos-duration="2000" className="cardText">

  <h2> Engellilerle Aileleri ile Dayanışma Derneği;</h2>
  <p>
  25/05/2018 Tarihinde Valilikçe tetkik edilerek  5253 sayılı dernekler kanununa  hükümlülüklerine göre kurulup faaliyete geçmiştir. Derneğimiz Engelli  ve Ailelerine destek amaçlı kurulmuştur. Derneğimizde gönüllülük esasında çalışılarak engellerin kalması yönünde mücadele edilmektedir. Her geçen gün daha çok destekçi ile daha çok engellimizin engellinin kalması yönünde çalışmalar yapılmaktadır. Engellilerimizin  çalışma hayatında ve sosyal yaşamda var olmaları için olmalarını desteklemektedir.Derneğimiz, engellilerin sosyal yaşama  etkin katılımı  sağlamak amacıyla projeler yapmayı hedeflemektedir.</p>

  </div>

<div data-aos="fade-left" data-aos-duration="2000"  className="cardVideo">
  <video src={video} autoPlay loop muted type="Video/mp4"/>
</div>
</div>
</div> 
</div>
</section>
  )
}

export default About
