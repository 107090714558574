import React,{useState} from 'react'
import './navbar.css'
import img from '../../Assets/Logo3.png'
import { AiFillCloseCircle } from 'react-icons/ai'
import { TbGridDots } from 'react-icons/tb'

const Navbar = () => {

const[active, setActive]=useState('navBar')
const showNav=()=>{
  setActive('navBar activeNavbar')
}
const handleEmailClick = () => {
  const email = 'bilgi@eadd.org.tr'; // Buraya hedef e-posta adresini yazın
  const subject = 'Gönüllü Başvurusu'; // Buraya e-posta konusunu yazın
  const body = 'Merhaba,\n\nBu bir örnek e-postadır.'; // Buraya e-posta içeriğini yazın
  
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoLink;
};
const removeNav =()=>{
  setActive('navBar')
}

const[transparent,setTransparent]=useState('header')
const addBg=()=>{
  if(window.scrollY>=10)
     { setTransparent('header activeHeader')}

  else{
    setTransparent('header')
  }
}
window.addEventListener('scroll',addBg)
  return (
    <section className='navBarSection'>
      
       <div className={transparent}>
      <div className="logoDiv"> 
      
          <div className='logo'> 
          <img src={img} alt="Image title"/>
          </div>
      </div>

      <div className={active}>
<ul className="navLists flex">
<li className="navItem">
  <a href="../../Components/Home.jsx" className="navLink">Anasayfa</a>
</li>
<li className="navItem">
  <a href="../../Components/Project.jsx" className="navLink">Projeler</a>
</li>

<li className="navItem">
  <a href="../../Components/Footer.jsx" className="navLink">İletişim</a>
</li>

<div className="headerBtns flex">
  <button className='btn loginBtn' onClick={handleEmailClick}>
    <a href="bilgi@eadd.org.tr">Gönüllü Ol</a>
  </button>
</div>
</ul>
          <div  onClick={removeNav} className="closeNavbar">
            <AiFillCloseCircle className="İcon"/></div>
          </div>
     

    <div onClick={showNav} className="toggleNavbar">
        <TbGridDots className="İcon"/>
    </div>     
    </div>
  </section>
  )
}

export default Navbar