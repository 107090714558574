import React from 'react'
import './app.css'
import About from './Components/About/About'
import Popular from './Components/Popular/Popular'
import Navbar from './Components/Navbar/Navbar'
import Project from './Components/Projects/Project'
import Home from './Components/Home/Home'
import Footer from './Components/Footer/Footer'





const App = () => {

  

  return (
   <>
  <Navbar/>
  <Home/>
  <Popular/>
  <Project/>
  <About/>
  <Footer/>
    </>
  )
}

export default App

